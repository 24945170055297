import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { Heading3 } from '@/src/styles/landingPageCommonStyles'
import colors from '@/src/styles/colors'
import { UserLanguageContext } from '@/src/locale/LocaleContext'
import { MediaQuery } from '@/src/utils/enums'

type ImgHeights = {
  pc: number
  tablet: number
  mobile: number
}

interface JoinTypersProps {
  logoNodes: React.ReactNode[]
  imgHeight?: ImgHeights
}

export default function JoinTypers({
  logoNodes,
  imgHeight = { pc: 40, tablet: 30, mobile: 20 },
}: JoinTypersProps) {
  const {
    textWithLocale: {
      website: { joinTypers },
    },
  } = useContext(UserLanguageContext)
  return (
    <JoinTypersWrapper>
      <Heading3 style={{ color: colors.gray11(), textAlign: 'center' }}>
        {joinTypers.heading3Txt}
      </Heading3>
      <JoinTypersImageBox imgHeight={imgHeight}>
        {logoNodes.map((node) => node)}
      </JoinTypersImageBox>
    </JoinTypersWrapper>
  )
}

const JoinTypersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${MediaQuery.PC} {
    gap: 80px;
  }
  ${MediaQuery.TABLET} {
    gap: 60px;
  }
  ${MediaQuery.MOBILE} {
    gap: 40px;
  }
`

const JoinTypersImageBox = styled.div<{ imgHeight: ImgHeights }>`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${MediaQuery.PC} {
    gap: 60px;

    img {
      height: ${({ imgHeight }) => imgHeight.pc}px;
    }
  }
  ${MediaQuery.TABLET} {
    gap: 40px;

    img {
      height: ${({ imgHeight }) => imgHeight.tablet}px;
    }
  }
  ${MediaQuery.MOBILE} {
    gap: 20px;

    img {
      height: ${({ imgHeight }) => imgHeight.mobile}px;
    }
  }
`
