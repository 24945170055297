import React from 'react'
import styled from '@emotion/styled'
import { MediaQuery } from '@/src/utils/enums'
import { Body1, Body2, Heading3 } from '@/src/styles/landingPageCommonStyles'
import colors from '@/src/styles/colors'

interface TestimonialProps {
  mainImgSrc: string
  logoImgSrc: string
  comments: string[]
  name: string
  job: string
}

export default function Testimonial({
  mainImgSrc,
  logoImgSrc,
  comments,
  name,
  job,
}: TestimonialProps) {
  return (
    <TestimonialWrapper>
      <TestimonialContentBox>
        <TestimonialImageBox>
          <TestimonialImage src={mainImgSrc} alt='' loading='lazy' />
        </TestimonialImageBox>
        <TestimonialTextBox>
          {logoImgSrc && (
            <LogoBox>
              <Logo src={logoImgSrc} />
            </LogoBox>
          )}
          <TestimonialCommentBox>
            {comments.map((comment) => (
              <Body1 key={comment} style={{ color: colors.gray11() }}>
                {comment}
              </Body1>
            ))}
          </TestimonialCommentBox>
          <TestimonialInfoBox>
            <Heading3 style={{ color: colors.gray11() }}>{name}</Heading3>
            <Body2 style={{ color: colors.gray33() }}>{job}</Body2>
          </TestimonialInfoBox>
        </TestimonialTextBox>
      </TestimonialContentBox>
    </TestimonialWrapper>
  )
}

const TestimonialWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const TestimonialContentBox = styled.div`
  display: flex;
  width: 100%;

  ${MediaQuery.PC} {
    gap: 80px;
    max-width: 1000px;
  }
  ${MediaQuery.TABLET} {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    max-width: 70%;
  }
  ${MediaQuery.MOBILE} {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    max-width: 70%;
  }
`

const TestimonialImageBox = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 20px;

  ${MediaQuery.PC} {
    width: 260px;
    height: 100%;
  }
  ${MediaQuery.TABLET} {
    width: 220px;
    height: 220px;
  }
  ${MediaQuery.MOBILE} {
    width: 160px;
    height: 160px;
  }
`

const TestimonialImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const TestimonialTextBox = styled.div`
  display: flex;
  flex-direction: column;

  ${MediaQuery.PC} {
    gap: 24px;
  }
  ${MediaQuery.TABLET} {
    gap: 24px;
  }
  ${MediaQuery.MOBILE} {
    gap: 12px;
  }
`

const LogoBox = styled.div`
  display: flex;
  width: 100%;

  ${MediaQuery.PC} {
    height: 40px;
  }
  ${MediaQuery.TABLET} {
    justify-content: center;
    height: 40px;
  }
  ${MediaQuery.MOBILE} {
    justify-content: center;
    height: 20px;
  }
`

const Logo = styled.img`
  height: 100%;
`

const TestimonialCommentBox = styled.div`
  display: flex;
  flex-direction: column;

  ${MediaQuery.NON_PC} {
    align-items: center;
    * {
      text-align: center;
    }
  }
`

const TestimonialInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  ${MediaQuery.TABLET} {
    align-items: center;
  }
  ${MediaQuery.MOBILE} {
    align-items: center;
  }
`
