import React, { useContext, useState } from 'react'
import styled from '@emotion/styled'
import colors from '@/src/styles/colors'
import InteractionManager from '@/src/utils/InteractionManager'
import { UserLanguageContext } from '@/src/locale/LocaleContext'
import { MediaQuery } from '@/src/utils/enums'

export default function LandingPageHeroParallax() {
  const {
    textWithLocale: {
      website: {
        landingPage: { heroImage },
      },
    },
  } = useContext(UserLanguageContext)

  const [mouseCoords, setMouseCoords] = useState({ x: 0, y: 0 })

  const handleParallaxMouseMove = (e: React.MouseEvent) => {
    const interactionManager = new InteractionManager()
    const { x: newX, y: newY } = interactionManager.getCoordsForParallax(e)

    setMouseCoords({ x: newX, y: newY })
  }

  return (
    <ParallaxWrapper
      onMouseMove={handleParallaxMouseMove}
      style={{
        transform: `perspective(1000px) rotateX(${mouseCoords.y}deg) rotateY(${mouseCoords.x}deg)`,
      }}
    >
      <ImageBackground />
      <MainImage src={heroImage.mainImgSrc} />
      <TaskImage src={heroImage.taskImgSrc} />
      <ResourceCard1Box>
        <ResourceCardShadowBox>
          <ResourceCardShadow />
        </ResourceCardShadowBox>
        <ResourceCardImgBox>
          <ResourceCard src={heroImage.resourceCard1ImgSrc} />
          <GreenCursor src={heroImage.greenCursorSrc} />
        </ResourceCardImgBox>
      </ResourceCard1Box>
      <ResourceCard2Box>
        <ResourceCardShadowBox>
          <ResourceCardShadow />
        </ResourceCardShadowBox>
        <ResourceCardImgBox>
          <ResourceCard src={heroImage.resourceCard2ImgSrc} />
          <PurpleCursor src={heroImage.purpleCursorSrc} />
        </ResourceCardImgBox>
      </ResourceCard2Box>
    </ParallaxWrapper>
  )
}

const ParallaxWrapper = styled.div`
  position: relative;
  width: 90%;
  transform-style: preserve-3d;
  will-change: transform;

  ${MediaQuery.MOBILE} {
    width: 100%;
  }
`

const ImageBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: conic-gradient(
    from 180deg at 50% 50%,
    #c070ff -21.1deg,
    #fb976c 108.58deg,
    #65f3da 246.22deg,
    #c070ff 338.9deg,
    #fb976c 468.58deg
  );
  filter: blur(150px);
  opacity: 40%;
`

const MainImage = styled.img`
  position: relative;
  width: 100%;
  border: 1px solid ${colors.darker12()};
  border-radius: 10px;
  background: ${colors.gray100()};
`

const TaskImage = styled.img`
  position: absolute;
  top: 50%;
  left: 100%;
  width: 18%;
  border: 1px solid ${colors.darker4()};
  border-radius: 5px;
  transform: translate(-50%, -50%) translateZ(70px);
  filter: drop-shadow(-5px 10px 25px rgba(41, 80, 125, 15%));
  pointer-events: none;
`

const ResourceCardShadowBox = styled.div`
  position: absolute;
  top: 0;
  left: 8%;
  overflow: hidden;
  width: 100%;
  height: 100%;
`

const ResourceCardShadow = styled.div`
  position: absolute;
  top: 0;
  left: -8%;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: rgba(0, 0, 0, 7%);
  filter: blur(10px);
`

const ResourceCardBox = styled.div`
  position: absolute;
  width: 19%;
  pointer-events: none;
  transform-style: preserve-3d;
`

const ResourceCard1Box = styled(ResourceCardBox)`
  top: 16%;
  left: -1.5%;
`

const ResourceCardImgBox = styled.div`
  transform: translateZ(100px);
`

const ResourceCard2Box = styled(ResourceCardBox)`
  top: 40%;
  left: -2%;
`

const ResourceCard = styled.img`
  width: 100%;
`

const Cursor = styled.img`
  position: absolute;
  height: 130%;
`

const GreenCursor = styled(Cursor)`
  top: -85%;
  left: -15%;
`
const PurpleCursor = styled(Cursor)`
  top: 60%;
  left: -20%;
`
