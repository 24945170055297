import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'

import { Body1, Heading2, Subtitle } from '@/src/styles/landingPageCommonStyles'
import colors from '@/src/styles/colors'
import { MediaQuery, Selectors } from '@/src/utils/enums'

interface LandingPageScrollEffectProps {
  texts: { heading: string[]; subtitle?: string; body: string[] }[]
  images: { image: React.ReactNode; ignoreCursor?: boolean }[]
  addMargin?: number
}

export default function LandingPageScrollEffect({
  texts,
  images,
  addMargin = 0,
}: LandingPageScrollEffectProps) {
  const [activeIdx, setActiveIdx] = useState(0)

  useEffect(() => {
    const rootElem = document.getElementById(Selectors.ROOT)
    if (!rootElem) return

    const textBoxes = document.querySelectorAll('[data-order]')

    function handleRootElemScroll(e: Event) {
      if (e.target instanceof Element) {
        const scrollPosition = e.target.scrollTop
        let idxToActivate = 0

        textBoxes.forEach((textBox, textBoxIdx) => {
          if (!(textBox instanceof HTMLElement)) return

          if (textBox.offsetTop - textBox.clientHeight / 2 <= scrollPosition) {
            idxToActivate = textBoxIdx
          }
        })
        setActiveIdx(idxToActivate)
      }
    }

    rootElem.addEventListener('scroll', handleRootElemScroll)

    return () => {
      rootElem.removeEventListener('scroll', handleRootElemScroll)
    }
  }, [])

  return (
    <ScrollEffectWrapper>
      <TextBox addMargin={addMargin}>
        {texts.map((text, textIdx) => {
          return (
            <Texts key={text.heading[0]} data-order={textIdx}>
              <HeadingTextBox>
                {text.heading.map((headTxt) => (
                  <Heading2 key={headTxt} style={{ color: colors.gray11() }}>
                    {headTxt}
                  </Heading2>
                ))}
              </HeadingTextBox>
              {text.subtitle && (
                <SubtitleLayout>
                  <Subtitle style={{ color: colors.gray11() }}>
                    {text.subtitle}
                  </Subtitle>
                </SubtitleLayout>
              )}
              <BodyTextLayout>
                {text.body.map((bodyTxt) => (
                  <Body1 key={bodyTxt} style={{ color: colors.gray33() }}>
                    {bodyTxt}
                  </Body1>
                ))}
              </BodyTextLayout>
            </Texts>
          )
        })}
      </TextBox>
      <StickyBox>
        {images.map((imageData, idx) => {
          return (
            <ImageBox
              style={{
                opacity: idx === activeIdx ? '100%' : '0%',
                pointerEvents: imageData.ignoreCursor ? 'none' : 'auto',
              }}
              key={'image' + idx}
            >
              {imageData.image}
            </ImageBox>
          )
        })}
      </StickyBox>
    </ScrollEffectWrapper>
  )
}

const ScrollEffectWrapper = styled.div`
  display: grid;
  grid-template: 1fr / repeat(12, 1fr);
  width: 100%;
`

const TextBox = styled.div<{ addMargin: number }>`
  grid-column: 1 / 6;

  ${MediaQuery.PC} {
    margin-left: ${({ addMargin }) => addMargin}px;
  }
`

const Texts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 76px);
`

const HeadingTextBox = styled.div`
  display: flex;
  flex-direction: column;
`

const SubtitleLayout = styled.div`
  ${MediaQuery.PC} {
    margin-top: 24px;
  }
  ${MediaQuery.TABLET} {
    margin-top: 16px;
  }
  ${MediaQuery.MOBILE} {
    margin-top: 12px;
  }
`

const BodyTextLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`

const StickyBox = styled.div`
  grid-column: 6 / 13;
  position: sticky;
  top: 76px;
  right: 0;
  height: calc(100vh - 76px);
`

const ImageBox = styled.div`
  position: absolute;
  top: 50%;
  left: calc(50% + 105px);
  width: 100%;
  transform: translate(-50%, -50%);
  transition: opacity 1s ease-in-out;
`
