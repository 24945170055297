import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { MediaQuery } from '@/src/utils/enums'
import { Body1, Heading2 } from '@/src/styles/landingPageCommonStyles'
import colors from '@/src/styles/colors'
import { UserLanguageContext } from '@/src/locale/LocaleContext'
import NewTabA from '@/src/components/elements/NewTabA'

export default function RecommendMobileApp() {
  const {
    textWithLocale: {
      website: {
        landingPage: { recommendMobileApp },
      },
    },
  } = useContext(UserLanguageContext)
  return (
    <RecommendMobileAppWrapper>
      <PCMobileAppExampleBox>
        <img src={recommendMobileApp.mobileImgSrc} loading='lazy' />
      </PCMobileAppExampleBox>
      <MobileAppExampleTextBox>
        <MobileAppExampleHeading2Box>
          {recommendMobileApp.headingTexts.map((text) => (
            <Heading2 style={{ color: colors.gray11() }} key={text}>
              {text}
            </Heading2>
          ))}
        </MobileAppExampleHeading2Box>
        <MobileAppExampleBodyBox>
          {recommendMobileApp.bodyTexts.map((text) => (
            <Body1 style={{ color: colors.gray33() }} key={text}>
              {text}
            </Body1>
          ))}
        </MobileAppExampleBodyBox>
        <MobileAppDownloadButtonBox>
          <NewTabA href={recommendMobileApp.appStoreUrl}>
            <img src={recommendMobileApp.appStoreSrc} loading='lazy' />
          </NewTabA>
          <NewTabA href={recommendMobileApp.googlePlayUrl}>
            <img src={recommendMobileApp.googlePlaySrc} loading='lazy' />
          </NewTabA>
        </MobileAppDownloadButtonBox>
      </MobileAppExampleTextBox>
      <NonPCMobileAppExampleBox>
        <img src={recommendMobileApp.mobileImgSrc} loading='lazy' />
      </NonPCMobileAppExampleBox>
    </RecommendMobileAppWrapper>
  )
}

const RecommendMobileAppWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${MediaQuery.PC} {
    justify-content: center;
  }
  ${MediaQuery.TABLET} {
    flex-direction: column;
  }
  ${MediaQuery.MOBILE} {
    flex-direction: column;
  }
`

const MobileAppExampleBox = styled.div`
  flex-shrink: 0;
  width: 400px;
  img {
    width: 100%;
  }
  ${MediaQuery.MOBILE} {
    width: 330px;
  }
`

const PCMobileAppExampleBox = styled(MobileAppExampleBox)`
  ${MediaQuery.NON_PC} {
    display: none;
  }
`
const NonPCMobileAppExampleBox = styled(MobileAppExampleBox)`
  ${MediaQuery.PC} {
    display: none;
  }
  ${MediaQuery.TABLET} {
    margin-top: 40px;
  }
  ${MediaQuery.MOBILE} {
    margin-top: 30px;
  }
`

const MobileAppExampleTextBox = styled.div`
  display: flex;
  flex-direction: column;

  ${MediaQuery.PC} {
  }
  ${MediaQuery.TABLET} {
    flex-shrink: 1;
    align-items: center;
    width: 100%;
  }
  ${MediaQuery.MOBILE} {
    flex-shrink: 1;
    align-items: center;
    width: 100%;
  }
`

const MobileAppExampleHeading2Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${MediaQuery.PC} {
  }
  ${MediaQuery.TABLET} {
    align-items: center;
  }
  ${MediaQuery.MOBILE} {
    align-items: center;
  }
`
const MobileAppExampleBodyBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${MediaQuery.PC} {
    gap: 2px;
    margin-top: 24px;
  }
  ${MediaQuery.TABLET} {
    align-items: center;
    margin-top: 16px;
  }
  ${MediaQuery.MOBILE} {
    align-items: center;
    margin-top: 12px;
  }
`
const MobileAppDownloadButtonBox = styled.div`
  display: flex;
  gap: 8px;

  img {
    height: 40px;
  }
  a {
    cursor: pointer;
  }

  ${MediaQuery.PC} {
    margin-top: 24px;
  }
  ${MediaQuery.TABLET} {
    margin-top: 16px;
  }
  ${MediaQuery.MOBILE} {
    margin-top: 12px;
  }
`
