import React from 'react'

export default class InteractionManager {
  getCoordsForParallax(e: React.MouseEvent, sensitivity = 0.005) {
    if (e.target instanceof Element) {
      const width = e.target.clientWidth
      const height = e.target.clientHeight
      const mouseX = e.clientX
      const mouseY = e.clientY

      const newX = (width / 2 - mouseX) * sensitivity
      const newY = -1 * (height / 2 - mouseY) * sensitivity
      return { x: newX, y: newY }
    }

    return { x: 0, y: 0 }
  }
}
