import React, { useContext, useState } from 'react'
import styled from '@emotion/styled'

import InteractionManager from '@/src/utils/InteractionManager'
import { UserLanguageContext } from '@/src/locale/LocaleContext'

export default function LandingPageScrollParallax() {
  const {
    textWithLocale: {
      website: {
        landingPage: { featureIntroductions },
      },
    },
  } = useContext(UserLanguageContext)

  const [mouseCoords, setMouseCoords] = useState({ x: 0, y: 0 })

  const handleParallaxMouseMove = (e: React.MouseEvent) => {
    const interactionManager = new InteractionManager()
    const { x: newX, y: newY } = interactionManager.getCoordsForParallax(
      e,
      0.01,
    )

    setMouseCoords({ x: newX, y: newY })
  }
  return (
    <LandingPageScrollParallaxWrapper
      style={{
        transform: `perspective(1000px) rotateX(${mouseCoords.y}deg) rotateY(${mouseCoords.x}deg)`,
      }}
      onMouseMove={handleParallaxMouseMove}
    >
      <BackgroundElement />
      <MainImage src={featureIntroductions[0].mainMediaSrc} loading='lazy' />
      <CursorImage src={featureIntroductions[0].subImgSrc} loading='lazy' />
    </LandingPageScrollParallaxWrapper>
  )
}

const LandingPageScrollParallaxWrapper = styled.div`
  position: relative;
  width: 100%;
  transform-style: preserve-3d;
`

const BackgroundElement = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: conic-gradient(
    from 180deg at 50.05% 52.54%,
    #50be7c -55.76deg,
    #ffcf88 138.43deg,
    #ffc89f 206.14deg,
    #50be7c 304.24deg,
    #ffcf88 498.43deg
  );
  filter: blur(100px);
  opacity: 50%;
`

const MainImage = styled.img`
  position: relative;
  width: 100%;
`

const CursorImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 69%;
  pointer-events: none;
  transform: translateX(-50%) translateY(-50%) translateZ(100px);
`
