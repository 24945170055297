import React, { useContext } from 'react'

import LandingPageLayout from '@/src/components/blocks/LandingPageLayout'
import HeroImageLayout from '@/src/components/blocks/HeroImageLayout'
import JoinTypers from '@/src/components/blocks/JoinTypers'
import Testimonial from '@/src/components/blocks/Testimonial'
import FeatureIntroductionLayout from '@/src/components/blocks/FeatureIntroductionLayout'
import ICPNavigator from '@/src/components/blocks/ICPNavigator'
import StartTyped from '@/src/components/blocks/StartTyped'
import RecommendMobileApp from '@/src/components/blocks/RecommendMobileApp'
import AfterTyped from '@/src/components/blocks/AfterTyped'
import LandingPageHeroParallax from '@/src/components/blocks/LandingPageHeroParallax'
import LandingPageScrollParallax from '@/src/components/blocks/LandingPageScrollParallax'
import LandingPageScrollEffect from '@/src/components/blocks/LandingPageScrollEffect'
import {
  ExampleImageShadow,
  ExampleImageWrapper,
  ExampleVideo,
  InPC,
  OutOfPC,
} from '@/src/styles/landingPageCommonStyles'
import { UserLanguageContext } from '@/src/locale/LocaleContext'

export default function LandingPage() {
  const {
    textWithLocale: {
      website: {
        landingPage: {
          featureIntroductions,
          heroImage,
          testimonial,
          joinTypersImgSrcs,
        },
      },
    },
  } = useContext(UserLanguageContext)

  const LetTheToolDoTheWork = () => (
    <ExampleImageWrapper>
      <ExampleImageShadow
        style={{
          background:
            'conic-gradient(from 180deg at 50% 50%,#fb976c -95.41deg,#eb70ff 7.5deg,#658df3 106.86deg,#fb976c 264.59deg,#eb70ff 367.5deg)',
        }}
      />
      <ExampleVideo
        muted
        loop
        autoPlay
        playsInline
        preload='none'
        src={featureIntroductions[1].mainMediaSrc}
      />
    </ExampleImageWrapper>
  )

  const BeOnTopOfYourGame = () => (
    <ExampleImageWrapper>
      <ExampleImageShadow
        style={{
          background:
            'conic-gradient(from 9.68deg at 44.69% 48.45%, #80C4D9 -17.32deg, #575CCE 108.33deg, #6FD5A0 214.97deg, #80C4D9 342.68deg, #575CCE 468.33deg)',
        }}
      />
      <ExampleVideo
        muted
        loop
        autoPlay
        playsInline
        preload='none'
        src={featureIntroductions[2].mainMediaSrc}
      />
    </ExampleImageWrapper>
  )

  return (
    <LandingPageLayout>
      <HeroImageLayout
        heading1Text={heroImage.heading1Text}
        body1Texts={heroImage.body1Texts}
        exampleImages={[<LandingPageHeroParallax key='parallax' />]}
      />
      <JoinTypers
        logoNodes={joinTypersImgSrcs.map((imgSrc) => (
          <img key={imgSrc} src={imgSrc} loading='lazy' />
        ))}
      />
      <Testimonial
        mainImgSrc={testimonial.mainImgSrc}
        logoImgSrc={testimonial.logoImgSrc}
        comments={testimonial.comments}
        name={testimonial.name}
        job={testimonial.job}
      />
      <InPC>
        <LandingPageScrollEffect
          addMargin={80}
          texts={[
            {
              heading: featureIntroductions[0].headingTexts,
              body: featureIntroductions[0].bodyTexts,
            },
            {
              heading: featureIntroductions[1].headingTexts,
              body: featureIntroductions[1].bodyTexts,
            },
            {
              heading: featureIntroductions[2].headingTexts,
              body: featureIntroductions[2].bodyTexts,
            },
          ]}
          images={[
            {
              image: <LandingPageScrollParallax key='scrollParallax' />,
            },
            {
              image: <LetTheToolDoTheWork key='letTheTool' />,
              ignoreCursor: true,
            },
            { image: <BeOnTopOfYourGame key='beOnTop' />, ignoreCursor: true },
          ]}
        />
      </InPC>
      <OutOfPC>
        <FeatureIntroductionLayout
          headingTexts={featureIntroductions[0].headingTexts}
          bodyTexts={featureIntroductions[0].bodyTexts}
          imageComponent={<LandingPageScrollParallax />}
        />
        <FeatureIntroductionLayout
          headingTexts={featureIntroductions[1].headingTexts}
          bodyTexts={featureIntroductions[1].bodyTexts}
          imageComponent={<LetTheToolDoTheWork />}
        />
        <FeatureIntroductionLayout
          headingTexts={featureIntroductions[2].headingTexts}
          bodyTexts={featureIntroductions[2].bodyTexts}
          imageComponent={<BeOnTopOfYourGame />}
        />
      </OutOfPC>
      <AfterTyped />
      <RecommendMobileApp />
      <ICPNavigator
        headingText='See how you can Typed'
        ICPsToUse={['founders', 'students', 'investors', 'creators']}
      />
      <StartTyped />
    </LandingPageLayout>
  )
}
