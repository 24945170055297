import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'

import { MediaQuery, Selectors } from '@/src/utils/enums'
import { UserLanguageContext } from '@/src/locale/LocaleContext'
import colors from '@/src/styles/colors'
import { Heading2 } from '@/src/styles/landingPageCommonStyles'

export default function AfterTyped() {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const stickyBoxRef = useRef<HTMLDivElement>(null)
  const [ratio, setRatio] = useState(0)

  const {
    isKorean,
    textWithLocale: {
      website: {
        landingPage: { afterTyped },
      },
    },
  } = useContext(UserLanguageContext)

  useEffect(() => {
    const rootElem = document.getElementById(Selectors.ROOT)
    if (!rootElem) return

    function handleRootScroll(e: Event) {
      if (!wrapperRef.current) return
      if (!stickyBoxRef.current) return
      if (e.target instanceof Element) {
        const LAYOUT_HEADER_HEIGHT = 76
        const scrollPosition = e.target.scrollTop + LAYOUT_HEADER_HEIGHT
        const wrapperPosition = wrapperRef.current.offsetTop
        const wrapperHeight = wrapperRef.current.clientHeight
        const stickyBoxHeight = stickyBoxRef.current.clientHeight

        const positionDiff = scrollPosition - wrapperPosition + stickyBoxHeight

        const ratioToUpdate = positionDiff / wrapperHeight

        setRatio(Math.max(Math.min(ratioToUpdate, 1), 0))
      }
    }

    rootElem.addEventListener('scroll', handleRootScroll)

    return () => {
      rootElem.removeEventListener('scroll', handleRootScroll)
    }
  }, [])
  return (
    <AfterTypedWrapper ref={wrapperRef}>
      <AfterTypedContentBox>
        <AfterTypedHeadingBox>
          {isKorean && (
            <Heading2
              style={{
                color: colors.gray60(),
              }}
            >
              {afterTyped.subHeading2Txt}
            </Heading2>
          )}
          <Heading2
            style={{
              color: colors.gray11(),
              textAlign: 'center',
            }}
          >
            {afterTyped.heading2Txt}
          </Heading2>
        </AfterTypedHeadingBox>
        <AfterTypedStickyBox ref={stickyBoxRef}>
          <AfterTypedEffectBox>
            <AfterTypedTextBox>
              <TypedText
                style={{
                  color: ratio < 0.7 ? colors.gray40() : colors.gray85(),
                }}
              >
                {afterTyped.beforeTypedTxt}
              </TypedText>
              <TypedText style={{ color: ratio > 0.7 ? '#856AE9' : '#DAD2F9' }}>
                {afterTyped.afterTypedTxt}
              </TypedText>
            </AfterTypedTextBox>
            <AfterTypedImageBox>
              <BeforeTypedImgBox style={{ width: `${100 - ratio * 100}%` }}>
                <BeforeTypedImg
                  src={afterTyped.beforeTypedSrc}
                  loading='lazy'
                />
              </BeforeTypedImgBox>
              <AfterTypedImg src={afterTyped.afterTypedSrc} loading='lazy' />
              <TypedImgDivider
                style={{ right: `calc(${ratio * 100}% - 2.5px)` }}
              />
            </AfterTypedImageBox>
          </AfterTypedEffectBox>
        </AfterTypedStickyBox>
      </AfterTypedContentBox>
    </AfterTypedWrapper>
  )
}

const AfterTypedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 200vw;
`

const AfterTypedContentBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;

  ${MediaQuery.PC} {
    gap: 80px;
  }
  ${MediaQuery.TABLET} {
    gap: 60px;
  }
  ${MediaQuery.MOBILE} {
    gap: 40px;
  }
`

const AfterTypedHeadingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const AfterTypedStickyBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  width: 100%;

  ${MediaQuery.PC} {
    top: 76px;
  }
  ${MediaQuery.TABLET} {
    top: 76px;
  }
  ${MediaQuery.MOBILE} {
    top: 30%;
  }
`

const AfterTypedEffectBox = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  ${MediaQuery.PC} {
    gap: 20px;
  }
  ${MediaQuery.TABLET} {
    gap: 15px;
  }
  ${MediaQuery.MOBILE} {
    gap: 12px;
  }
`

const AfterTypedTextBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const TypedText = styled.div`
  font-weight: 700;
  line-height: 1.5;
  transition: color 0.2s ease-in-out;

  ${MediaQuery.PC} {
    font-size: 35px;
  }
  ${MediaQuery.TABLET} {
    font-size: 25px;
  }
  ${MediaQuery.MOBILE} {
    font-size: 12px;
  }
`

const AfterTypedImageBox = styled.div`
  position: relative;
  width: 100%;
`

const BeforeTypedImgBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100%;
  will-change: width;
`

const TypedImgDivider = styled.div`
  position: absolute;
  top: 0;
  width: 5px;
  height: calc(100% + 32px);
  background-color: ${colors.purple50()};
  will-change: right;

  ${MediaQuery.PC} {
    width: 5px;
  }
  ${MediaQuery.TABLET} {
    width: 4px;
  }
  ${MediaQuery.MOBILE} {
    width: 3px;
  }
`

const BeforeTypedImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 20px;

  ${MediaQuery.MOBILE} {
    border-radius: 8px;
  }
`

const AfterTypedImg = styled.img`
  width: 100%;
  border-radius: 20px;

  ${MediaQuery.MOBILE} {
    border-radius: 8px;
  }
`
